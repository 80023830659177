<template>
    <div class="role-configuration">
        <b-button variant="secondary" @click="addNew"><PlusIcon /> Add Role</b-button>
        <b-table class="mt-2" table-class="light" :fields="fields" :items="roles" head-variant="success">
            <template v-slot:cell(actions)="data">
                <b-button variant="outline-dark" size="sm" @click="edit(data.item.id)">Edit</b-button>
            </template>
        </b-table>
        <AddRoleModal ref="addRoleModal" @role-added="loadRoles" />
        <EditRoleModal ref="editRoleModal" @role-updated="loadRoles" />
    </div>
</template>

<script>
    import PlusIcon from 'vue-material-design-icons/Plus';
    import Providers from '../../../providers/index';
    import AddRoleModal from "./modal/AddRoleModal";
    import EditRoleModal from "./modal/EditRoleModal";

    export default {
        name: "RoleConfig",
        components: {EditRoleModal, AddRoleModal, PlusIcon},
        data() {
            return {
                roles: [

                ],
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'cpdHours',
                        label: 'Required CPD Hours'
                    },
                    {
                        key: 'cceHours',
                        label: 'Required CCE Hours'
                    },
                    {
                        key: 'actions',
                        label: 'Actions',
                    }
                ]
            }
        },
        methods: {
            loadRoles() {
                Providers.admin.configuration.roles().then((resp) => {
                    this.roles = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load roles', resp);
                });
            },
            addNew() {
                this.$refs.addRoleModal.show();
            },
            edit(roleId) {
                this.$refs.editRoleModal.show(roleId);
            }
        },
        created() {
            this.loadRoles();
        }
    }
</script>

<style scoped>

</style>