<template>
    <div class="edit-training-modal">
        <b-modal ref="modal" title="Edit Training" size="lg" header-bg-variant="primary" header-text-variant="light"
                 ok-title="Save Changes" ok-variant="primary" @ok="save">
            <b-overlay :show="loading">
                <TrainingItemInformation :training-item="trainingItem" v-if="trainingItem" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import TrainingItemInformation from "../partials/TrainingItemInformation";
    import Providers from '../../../../providers/index';

    export default {
        name: "EditTrainingModal",
        components: {TrainingItemInformation},
        data() {
            return {
                loading: false,
                trainingItemId: null,
                trainingItem: null
            }
        },
        methods: {
            show(trainingItemId) {
                this.reset();
                this.trainingItemId = trainingItemId;
                this.$refs.modal.show();
                this.loading = true;
                Providers.admin.configuration.trainingItem(trainingItemId).then((resp) => {
                    this.trainingItem = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load training item', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.trainingItemId = null;
                this.trainingItem = null;
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateTrainingItem(this.trainingItemId, this.trainingItem).then(() => {
                    this.$success('Training Item Updated', 'The training item has been updated successfully');
                    this.$emit('training-item-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update training item', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>