<template>
    <div class="add-facility-modal">
        <b-modal ref="modal" title="Add Facility" header-bg-variant="primary" header-text-variant="light"
                 ok-title="Add Facility" ok-variant="primary" no-close-on-esc no-close-on-backdrop @ok="create"
                :ok-disabled="loading">
            <FacilityInformation :facility="facility" />
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import FacilityInformation from "../partials/FacilityInformation";
    import DEFAULT_FACILITY from '../defaults/defaultFacility';

    export default {
        name: "AddFacilityModal",
        components: {FacilityInformation},
        data() {
            return {
                unitId: null,
                facility: {...DEFAULT_FACILITY},
                loading: false,
            }
        },
        methods: {
            show(unitId) {
                this.reset();
                this.unitId = unitId;
                this.$refs.modal.show();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.createFacility(this.unitId, this.facility.name).then(() => {
                    this.$success('Facility Created', 'Facility was created successfully');
                    this.$emit('facility-added');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to create facility', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.unitId = null;
                this.facility = {...DEFAULT_FACILITY}
            }
        }
    }
</script>

<style scoped>

</style>