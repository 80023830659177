<template>
    <div class="edit-unit-modal">
        <b-modal ref="modal" title="Edit Unit" no-close-on-backdrop no-close-on-esc ok-variant="primary" header-bg-variant="primary" header-text-variant="light"
                 ok-title="Save Changes" @ok="save" :ok-disabled="loading || pendingFileUpload" :cancel-disabled="loading || pendingFileUpload">
            <b-overlay :show="loading">
                <UnitInformation v-if="unit" :unit="unit" @upload-completed="pendingFileUpload = false" @upload-inprogress="pendingFileUpload = true" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import UnitInformation from "../partials/UnitInformation";
    import Providers from '../../../../providers/index';

    export default {
        name: "EditUnitModal",
        components: {UnitInformation},
        data() {
            return {
                loading: false,
                unitId: null,
                unit: null,
                pendingFileUpload: false
            }
        },
        methods: {
            show(unitId) {
                this.unitId = unitId;
                this.loading = true;
                this.$refs.modal.show();
                Providers.admin.configuration.unit(unitId).then((resp) => {
                    this.unit = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get unit', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateUnit(this.unitId, this.unit).then(() => {
                    this.$success('Unit Updated', 'The unit has been updated successfully');
                    this.$emit('unit-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update unit', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.unitId = null;
                this.unit = null;
            }
        }
    }
</script>

<style scoped>

</style>