<template>
    <div class="training-config">
        <b-overlay :show="loading">
            <b-button variant="secondary" @click="addNew"><PlusIcon /> Add Training</b-button>
            <b-table class="mt-2" table-class="light" :fields="fields" :items="trainingItems">
                <template v-slot:cell(title)="data">
                    {{data.item.title}} <b-badge v-if="data.item.archived">Archived</b-badge>
                </template>
                <template v-slot:cell(roles)="data">
                    <b-badge class="mr-2" v-for="mapping in data.item.roles" v-bind:key="mapping.role.id" :variant="mapping.required ? 'info' : 'secondary'">{{mapping.role.name}}</b-badge>
                </template>
                <template v-slot:cell(actions)="data">
                    <div class="actions">
                        <b-button variant="outline-secondary" size="sm" @click="edit(data.item.id)"><PencilIcon /> Edit</b-button>
                        <b-button variant="outline-danger" size="sm" @click="archive(data.item.id)" v-if="!data.item.archived"><ArchiveIcon /> Archive</b-button>
                        <b-button variant="outline-warning" size="sm" @click="unarchive(data.item.id)" v-if="data.item.archived"><RestoreIcon /> Restore</b-button>
                    </div>
                </template>
            </b-table>
            <b-badge variant="info">Required</b-badge> <b-badge class="ml-2" variant="secondary">Optional</b-badge>
            <AddTrainingModal ref="addTrainingModal" @training-item-added="refresh" />
            <EditTrainingModal ref="editTrainingModal" @training-item-updated="refresh" />
        </b-overlay>
    </div>
</template>

<script>
    import Providers from '../../../providers/index';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import ArchiveIcon from 'vue-material-design-icons/Archive';
    import RestoreIcon from 'vue-material-design-icons/Restore';
    import AddTrainingModal from "./modal/AddTrainingModal";
    import EditTrainingModal from "./modal/EditTrainingModal";

    export default {
        name: "TrainingItemConfig",
        components: {EditTrainingModal, AddTrainingModal, PencilIcon, PlusIcon, ArchiveIcon, RestoreIcon},
        data() {
            return {
                fields: [
                    {
                        key: 'title',
                        label: 'Title'
                    },
                    {
                        key: 'link',
                        label: 'Link'
                    },
                    {
                        key: 'frequency',
                        label: 'Frequency',
                        formatter: function(val) {
                            if (val != null) {
                                return val + ' Year(s)';
                            }
                            return 'Once';
                        }
                    },
                    {
                        key: 'roles',
                        label: 'Assigned to Roles'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                trainingItems: [],
                loading: false
            }
        },
        methods: {
            loadTrainingItems() {
                this.loading = true;
                Providers.admin.configuration.trainingItems().then((resp) => {
                    this.trainingItems = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get training items', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            refresh() {
                this.loadTrainingItems();
            },
            addNew() {
                this.$refs.addTrainingModal.show();
            },
            edit(trainingItemId) {
                this.$refs.editTrainingModal.show(trainingItemId);
            },
            archive(id) {
                Providers.admin.configuration.archiveTrainingItem(id).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to archive item', resp);
                });
            },
            unarchive(id) {
                Providers.admin.configuration.unarchiveTrainingItem(id).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to unarchive item', resp);
                });
            }
        },
        created() {
            this.loadTrainingItems();
        }
    }
</script>

<style scoped>

    div.actions {
        min-width: 100px;
    }

    div.actions > * {
        margin-right: 5px;
    }
</style>