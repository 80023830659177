<template>
    <div class="sop-information">
        <b-form-group label="Title">
            <b-form-input v-model="scopeOfPractice.title" autocomplete="off" />
        </b-form-group>
    </div>
</template>

<script>

    import DEFAULT_SOP from '../defaults/defaultScopeOfPractice';

    export default {
        name: "ScopeOfPracticeInformation",
        props: {
            scopeOfPractice: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_SOP})
            }
        },
    }
</script>

<style scoped>

</style>