<template>
    <div class="unit-configuration">
        <b-overlay :show="loading">
            <b-button @click="addUnit"><PlusIcon /> Add Unit</b-button>
            <b-table class="mt-2" table-class="light" :fields="fields" :items="units">
                <template v-slot:cell(actions)="data">
                    <div class="actions">
                        <b-button size="sm" variant="light" @click="openUnit(data.item.id)"><MenuIcon /> View Facilities</b-button>
                        <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit Unit</b-button>
                    </div>
                </template>
                <template v-slot:cell(logo)="data">
                    <img v-if="data.item.logo" :src="logoUrl(data.item.logo)" style="max-width: 45px; max-height: 45px;"/>
                </template>
            </b-table>
        </b-overlay>
        <ViewFacilitiesModal ref="viewFacilitiesModal" />
        <AddUnitModal ref="addUnitModal" @unit-added="loadUnits" />
        <EditUnitModal ref="editUnitModal" @unit-updated="loadUnits" />
    </div>
</template>

<script>
    import PlusIcon from 'vue-material-design-icons/Plus';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import Providers from '../../../providers/index';
    import MenuIcon from 'vue-material-design-icons/Menu'
    import AddUnitModal from "./modal/AddUnitModal";
    import ViewFacilitiesModal from "./modal/ViewFacilitiesModal";
    import EditUnitModal from "./modal/EditUnitModal";

    export default {
        name: "UnitConfig",
        components: {EditUnitModal, ViewFacilitiesModal, AddUnitModal, PlusIcon, MenuIcon, PencilIcon},
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'logo',
                        label: 'Logo'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                units: [],
                loading: false
            }
        },
        methods: {
            loadUnits() {
                this.loading = true;
                Providers.admin.configuration.units().then((resp) => {
                    this.units = resp.data;
                }).catch((resp) => {
                    this.$httpError('Failed to load units', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            addUnit() {
                this.$refs.addUnitModal.show();
            },
            openUnit(unitId) {
                this.$refs.viewFacilitiesModal.show(unitId);
            },
            logoUrl(storageId) {
                return `${process.env.VUE_APP_API_ROOT}file/system/${storageId}`;
            },
            edit(unitId) {
                this.$refs.editUnitModal.show(unitId);
            }
        },
        created() {
            this.loadUnits();
        }
    }
</script>

<style scoped>
    div.actions > * {
        margin-right: 5px;
    }
</style>