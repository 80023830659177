<template>
    <div class="configuration">
        <b-card no-body>
            <b-tabs content-class="mt-3" lazy card>
                <b-tab title="Roles"><RoleConfig /></b-tab>
                <b-tab title="Organisation"><UnitConfig /></b-tab>
                <b-tab title="Training"><TrainingItemConfig /></b-tab>
                <b-tab title="Clinical Skills"><ClinicalSkillsConfig /></b-tab>
                <b-tab title="Scopes of Practice"><ScopeOfPracticeConfig /></b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
    import RoleConfig from "../../components/admin/configuration/RoleConfig";
    import TrainingItemConfig from "../../components/admin/configuration/TrainingItemConfig";
    import ClinicalSkillsConfig from "../../components/admin/configuration/ClinicalSkillsConfig";
    import UnitConfig from "../../components/admin/configuration/UnitConfig";
    import ScopeOfPracticeConfig from "../../components/admin/configuration/ScopeOfPracticeConfig";
    export default {
        name: "SystemConfiguration",
        components: {ScopeOfPracticeConfig, UnitConfig,  ClinicalSkillsConfig, TrainingItemConfig, RoleConfig}
    }
</script>

<style scoped>

</style>