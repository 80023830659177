let trainingItem = {
    title: '',
    description: '',
    link: '',
    guidanceLink: '',
    frequency: 0,
    linkedSkills: [],
    roles: []
};

export default trainingItem;