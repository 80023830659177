<template>
    <div class="add-role-modal">
        <b-modal title="Add Role" ref="modal" header-bg-variant="primary" header-text-variant="light" @ok="create" ok-title="Create" ok-variant="success" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <RoleInformation v-if="role" :role="role" />
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import RoleInformation from "../partials/RoleInformation";
    import DEFAULT_ROLE from '../defaults/defaultRole';

    export default {
        name: "AddRoleModal",
        components: {RoleInformation},
        data() {
            return {
                role: {...DEFAULT_ROLE},
                loading: false
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            reset() {
                this.role = {...DEFAULT_ROLE};
            },
            create() {
                this.loading = true;
                Providers.admin.configuration.addRole(this.role).then(() => {
                    this.$success('Added Role', 'Role has been added successfully');
                    this.$emit('role-added');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to add role', resp);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
</script>

<style scoped>

</style>