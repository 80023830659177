<template>
    <div class="edit-clinical-skill-modal">
        <b-modal ref="modal" title="Edit Skill" header-bg-variant="primary" header-text-variant="light" @ok="save"
                 ok-variant="primary" ok-title="Save Changes" :ok-disabled="loading" :cancel-disabled="loading"
                 no-close-on-esc no-close-on-backdrop>
            <ClinicalSkillInformation :skill="skill" v-if="skill" />
        </b-modal>
    </div>
</template>

<script>
    import ClinicalSkillInformation from "../partials/ClinicalSkillInformation";
    import Providers from '../../../../providers/index';

    export default {
        name: "EditClinicalSkillModal",
        components: {ClinicalSkillInformation},
        data() {
            return {
                loading: false,
                groupId: null,
                skillId: null,
                skill: null
            }
        },
        methods: {
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateClinicalSkill(this.groupId, this.skillId, this.skill).then(() => {
                    this.$success('Clinical Skill Updated', 'The clinical skill has been updated successfully');
                    this.$emit('skill-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update clinical skill', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            show(groupId, skillId) {
                this.skill = null;
                this.groupId = groupId;
                this.skillId = skillId;
                this.$refs.modal.show();
                this.loading = true;
                Providers.admin.configuration.clinicalSkill(groupId, skillId).then((resp) => {
                    this.skill = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load skill', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            }
        }
    }
</script>

<style scoped>

</style>