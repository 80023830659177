<template>
    <div class="clinical-skill-information">
        <b-form-group label="Name">
            <b-input type="text" v-model="skill.name"></b-input>
        </b-form-group>
        <b-form-group label="Expiry (years)">
            <b-form-spinbutton min="1" max="5" v-model="skill.expiry"></b-form-spinbutton>
        </b-form-group>
        <b-form-group label="Assigned Roles">
            <b-checkbox-group stacked switches :options="roles" v-model="skill.roles"></b-checkbox-group>
        </b-form-group>
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import DEFAULT_SKILL from '../defaults/defaultClinicalSkill';

    export default {
        name: "ClinicalSkillInformation",
        props: {
            skill: {
                type: Object,
                default: () => ({...DEFAULT_SKILL}),
                required: false,
            }
        },
        data() {
            return {
                roles: []
            }
        },
        methods: {
            loadRoles() {
                this.loading = true;
                Providers.admin.configuration.roles().then((resp) => {
                    this.roles = resp.body.map(r => {
                        return {
                            value: r.id,
                            text: r.name
                        }
                    })
                }).catch((resp) => {
                    this.$httpError('Failed to load roles', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadRoles();
        }
    }
</script>

<style scoped>

</style>