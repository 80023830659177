<template>
    <div class="add-sop-modal">
        <b-modal title="Add Scope of Practice" ref="modal" @ok="create" ok-title="Create" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <ScopeOfPracticeInformation :scope-of-practice="scopeOfPractice" />
        </b-modal>
    </div>
</template>

<script>
    import ScopeOfPracticeInformation from "../partials/ScopeOfPracticeInformation";
    import DEFAULT_SOP from '../defaults/defaultScopeOfPractice';
    import Providers from '../../../../providers';

    export default {
        name: "AddScopeOfPracticeModal",
        components: {ScopeOfPracticeInformation},
        data() {
            return {
                scopeOfPractice: null,
                groupId: null,
                categoryId: null,
                loading: false,
            }
        },
        methods: {
            show(groupId, categoryId) {
                this.reset();
                this.groupId = groupId;
                this.categoryId = categoryId;
                this.$refs.modal.show();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.createScopeOfPractice(this.groupId, this.categoryId, this.scopeOfPractice).then(() => {
                    this.$success('Scope of Practice Added', 'The scope of practice has been added');
                    this.$emit('scope-of-practice-added');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to create scope of practice', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.scopeOfPractice = {...DEFAULT_SOP};
            }
        }
    }
</script>

<style scoped>

</style>