<template>
    <div class="clinical-skills-configuration">
        <b-overlay :show="loading">
            <h2>Groups</h2>
            <b-button @click="createGroup"><PlusIcon /> Create Group</b-button>
            <b-table class="mt-2" table-class="light" :fields="groupFields" :items="groups">
                <template v-slot:cell(actions)="data">
                    <div class="actions">
                        <b-button variant="light" size="sm" @click="viewGroup(data.item)"><FormatListBulletedIcon /> View Skills</b-button>
                        <b-button variant="outline-primary" size="sm" @click="addSkill(data.item.id)"><PlusIcon /> Add Skill</b-button>
                        <b-button variant="outline-dark" size="sm" @click="editGroup(data.item.id)"><PencilIcon /> Edit Group</b-button>
                    </div>
                </template>
            </b-table>
            <AddClinicalSkillsGroupModal ref="addGroupModal" @group-created="loadGroups" />
            <EditClinicalSkillsGroupModal ref="editGroupModal" @group-updated="loadGroups" />
            <ViewClinicalSkillsGroupModal ref="viewGroupModal" />
            <AddClinicalSkillModal ref="addSkillModal" />
        </b-overlay>
    </div>
</template>

<script>
    import Providers from '../../../providers/index';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted';
    import AddClinicalSkillsGroupModal from "./modal/AddClinicalSkillsGroupModal";
    import ViewClinicalSkillsGroupModal from "./modal/ViewClinicalSkillsGroupModal";
    import AddClinicalSkillModal from "./modal/AddClinicalSkillModal";
    import EditClinicalSkillsGroupModal from "./modal/EditClinicalSkillsGroupModal";

    export default {
        name: "ClinicalSkillsConfig",
        components: {
            EditClinicalSkillsGroupModal,
            AddClinicalSkillModal,
            ViewClinicalSkillsGroupModal, AddClinicalSkillsGroupModal, PlusIcon, PencilIcon, FormatListBulletedIcon},
        data() {
            return {
                groupFields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                groups: [],
                loading: false,
            }
        },
        methods: {
            loadGroups() {
                this.loading = true;
                Providers.admin.configuration.clinicalSkillsGroups().then((resp) => {
                    this.groups = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load Clinical Skills Groups', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            viewGroup(group) {
                this.$refs.viewGroupModal.show(group);
            },
            addSkill(groupId) {
                this.$refs.addSkillModal.show(groupId);
            },
            createGroup() {
                this.$refs.addGroupModal.show();
            },
            editGroup(groupId) {
                this.$refs.editGroupModal.show(groupId);
            }
        },
        created() {
            this.loadGroups();
        }
    }
</script>

<style scoped>
    .actions > * {
        margin-right: 5px;
    }
</style>