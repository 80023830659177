<template>
    <div class="edit-sop-category-modal">
        <b-modal title="Edit Category" ref="modal" @ok="save" ok-title="Save Changes" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <ScopeOfPracticeCategoryInformation :category="category" v-if="category" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import ScopeOfPracticeCategoryInformation from "../partials/ScopeOfPracticeCategoryInformation";
    import Providers from "../../../../providers";

    export default {
        name: "EditScopeOfPracticeCategoryModal",
        components: {ScopeOfPracticeCategoryInformation},
        data() {
            return {
                loading: false,
                category: null,
                groupId: null,
                categoryId: null
            }
        },
        methods: {
            show(groupId, categoryId) {
                this.reset();
                this.$refs.modal.show();
                this.groupId = groupId;
                this.categoryId = categoryId;
                this.loading = true;
                Providers.admin.configuration.scopeOfPracticeCategory(groupId, categoryId).then((resp) => {
                    this.category = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get category', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateScopeOfPracticeCategory(this.groupId, this.categoryId, this.category).then(() => {
                    this.$success('Category Updated', 'The scope of practice category has been updated');
                    this.$emit('category-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update category', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.groupId = null;
                this.categoryId = null;
                this.category = null;
            }
        }
    }
</script>

<style scoped>

</style>