<template>
    <div class="add-clinical-skill-modal">
        <b-modal ref="modal" title="Add Skill" header-bg-variant="primary" header-text-variant="light" @ok="create" ok-variant="primary" ok-title="Create" :ok-disabled="loading" :cancel-disabled="loading" no-close-on-esc no-close-on-backdrop>
            <b-overlay :show="loading">
                <ClinicalSkillInformation :skill="request" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>

    import Providers from '../../../../providers/index';
    import ClinicalSkillInformation from "../partials/ClinicalSkillInformation";
    import DEFAULT_SKILL from '../defaults/defaultClinicalSkill';

    export default {
        name: "AddClinicalSkillModal",
        components: {ClinicalSkillInformation},
        data() {
            return {
                loading: false,
                request: {...DEFAULT_SKILL},
                groupId: null,
            }
        },
        methods: {
            show(groupId) {
                this.reset();
                this.groupId = groupId;
                this.$refs.modal.show();
            },
            create() {
                this.loading = true;
                Providers.admin.configuration.createClinicalSkill(this.groupId, this.request.name, this.request.expiry, this.request.roles).then(() => {
                    this.$success('Skill Created', 'The skill has been added to the group');
                    this.$emit('skill-created');
                }).catch((resp) => {
                    this.$httpError('Failed to create skill', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.request = {...DEFAULT_SKILL}
            }
        },
        created() {
            this.reset();
        }
    }
</script>

<style scoped>

</style>