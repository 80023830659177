<template>
    <div class="sop-group-modal">
        <b-modal :title="modalTitle" size="lg" ref="modal" header-bg-variant="primary" header-text-variant="light" ok-only ok-title="Close" ok-variant="light">
            <b-overlay :show="loading">
                <h2>Categories</h2>
                <b-button variant="dark" @click="addCategory"><PlusIcon /> Add Category</b-button>
                <b-table table-class="light" class="mt-2" :fields="fields" :items="categories">
                    <template v-slot:cell(actions)="data">
                        <div class="actions">
                            <b-button size="sm" variant="light" @click="viewCategory(data.item)"><FormatListBulletedIcon /> View Scopes of Practice</b-button>
                            <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit Category</b-button>
                        </div>

                    </template>
                </b-table>
            </b-overlay>
        </b-modal>
        <AddScopeOfPracticeCategoryModal ref="addCategoryModal" @category-added="loadCategories" />
        <EditScopeOfPracticeCategoryModal ref="editCategoryModal" @category-updated="loadCategories" />
        <ViewScopeOfPracticeCategoryModal ref="viewCategoryModal" />
    </div>
</template>

<script>
    import Providers from '../../../../providers';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted';
    import AddScopeOfPracticeCategoryModal from "./AddScopeOfPracticeCategoryModal";
    import EditScopeOfPracticeCategoryModal from "./EditScopeOfPracticeCategoryModal";
    import ViewScopeOfPracticeCategoryModal from "./ViewScopeOfPracticeCategoryModal";

    export default {
        name: "ViewScopeOfPracticeGroupModal",
        components: {
            ViewScopeOfPracticeCategoryModal,
            EditScopeOfPracticeCategoryModal, AddScopeOfPracticeCategoryModal, PencilIcon, PlusIcon, FormatListBulletedIcon},
        data() {
            return {
                loading: false,
                group: null,
                categories: [],
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        computed: {
            modalTitle() {
                return 'Group: ' + ((this.group !== null) ? this.group.name : '');
            }
        },
        methods: {
            show(group) {
                this.group = group;
                this.loadCategories();
                this.$refs.modal.show();
            },
            loadCategories() {
                this.loading = true;
                Providers.admin.configuration.scopeOfPracticeCategories(this.group.id).then((resp) => {
                    this.categories = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get categories', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            addCategory() {
                this.$refs.addCategoryModal.show(this.group.id);
            },
            viewCategory(category) {
                this.$refs.viewCategoryModal.show(this.group.id, category);
            },
            edit(categoryId) {
                this.$refs.editCategoryModal.show(this.group.id, categoryId);
            }
        }
    }
</script>

<style scoped>
.actions > * {
    margin-right: 5px;
}
.actions {
    min-width: 280px;
}
</style>