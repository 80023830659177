<template>
    <div class="facilities-modal">
        <b-modal title="Facilities" size="lg" ref="modal" header-bg-variant="primary" header-text-variant="light"
                 no-close-on-backdrop no-close-on-esc ok-title="Close" ok-variant="dark" ok-only>
            <b-overlay :show="loading">
                <b-button variant="dark" @click="addFacility"><PlusIcon /> Add Facility</b-button>
                <b-table class="mt-2" :fields="fields" :items="facilities" table-class="light">
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit</b-button>
                    </template>
                </b-table>
            </b-overlay>
        </b-modal>
        <AddFacilityModal ref="addFacilityModal" @facility-added="loadFacilities"/>
        <EditFacilityModal ref="editFacilityModal" @facility-updated="loadFacilities" />
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import AddFacilityModal from "./AddFacilityModal";
    import EditFacilityModal from "./EditFacilityModal";

    export default {
        name: "ViewFacilitiesModal",
        components: {EditFacilityModal, AddFacilityModal, PlusIcon, PencilIcon},
        data() {
            return {
                loading: true,
                unitId: null,
                facilities: [],
                fields: [
                    {
                        key: 'name'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        computed: {

        },
        methods: {
            show(unitId) {
                this.reset();
                this.unitId = unitId;
                this.facilities = [];
                this.loadFacilities();
                this.$refs.modal.show();
            },
            addFacility() {
                this.$refs.addFacilityModal.show(this.unitId);
            },
            edit(facilityId) {
                this.$refs.editFacilityModal.show(this.unitId, facilityId);
            },
            loadFacilities() {
                this.loading = true;
                Providers.admin.configuration.facilities(this.unitId).then((resp) => {
                    this.facilities = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get facilities', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.unitId = null;
                this.facilities = [];
            }
        }
    }
</script>

<style scoped>

</style>