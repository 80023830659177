<template>
    <div class="training-item-information">
        <b-form-group label="Title">
            <b-input type="text" v-model="trainingItem.title"></b-input>
        </b-form-group>
        <b-form-group label="Link to Training">
            <b-input type="text" v-model="trainingItem.link"></b-input>
        </b-form-group>
        <b-form-group label="Link to Guidance">
            <b-input type="text" v-model="trainingItem.guidanceLink"></b-input>
        </b-form-group>
        <b-form-group label="Description">
            <b-textarea rows="5" v-model="trainingItem.description"></b-textarea>
        </b-form-group>
        <b-form-group label="Frequency (in years)">
            <b-form-spinbutton min="0" max="5" v-model="trainingItem.frequency"></b-form-spinbutton>
            <small>A frequency of 0 means that the training will never expire.</small>
        </b-form-group>
        <b-form-group label="Linked Skills">
            <v-select :options="clinicalSkills" label="text" v-model="trainingItem.linkedSkills" :reduce="option => option.value" placeholder="Please select skills" multiple>

            </v-select>
        </b-form-group>
        <b-form-group label="">
            <b-table small table-class="light" :items="roles" :fields="roleTableFields">
                <template v-slot:cell(mapping)="data">
                    <b-form-radio-group size="sm" button-variant="outline-primary" v-model="data.item.assignment" :options="assignmentOptions" buttons @input="updateRoleMappings">

                    </b-form-radio-group>
                </template>
            </b-table>
        </b-form-group>

    </div>
</template>

<script>
    import DEFAULT_TRAINING_ITEM from '../defaults/defaultTrainingItem';
    import Providers from '../../../../providers/index';
    import vSelect from 'vue-select';

    export default {
        name: "TrainingItemInformation",
        components: {vSelect},
        props: {
            trainingItem: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_TRAINING_ITEM})
            }
        },
        data() {
            return {
                loading: true,
                roles: [],
                clinicalSkills: [],
                roleTableFields: [
                    {
                        key: 'name',
                        label: 'Role'
                    },
                    {
                        key: 'mapping',
                        label: 'Assignment'
                    }
                ],
                assignmentOptions: [
                    { text: 'None', value: 0 },
                    { text: 'Optional', value: 1 },
                    { text: 'Required', value: 2 },
                ],
            }
        },
        methods: {
            updateRoleMappings() {
                this.trainingItem.roles = this.roles.filter(r => r.assignment !== 0).map(r => {
                    return {
                        role: r.id,
                        required: r.assignment === 2
                    }
                });
            },
            loadRoles() {
                this.loading = true;
                Providers.admin.configuration.roles().then((resp) => {
                    this.roles = resp.body.map(r => {
                        let assignment = 0;
                        for (let a of this.trainingItem.roles) {
                            if (a.role === r.id) {
                                assignment = (a.required) ? 2 : 1;
                            }
                        }
                        r.assignment = assignment;
                        return r;
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to load roles', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            loadSkills() {
                this.loading = true;
                Providers.admin.configuration.allSkills().then((resp) => {
                    let groups = resp.body;
                    let allSkills = [];
                    for (let group of groups) {
                        for (let skill of group.skills) {
                            allSkills.push({
                                text: skill.name,
                                value: skill.id
                            })
                        }
                    }
                    this.clinicalSkills = allSkills;
                }).catch((resp) => {
                    this.$httpError('Failed to load skills', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.loadRoles();
            this.loadSkills();
        }
    }
</script>

<style scoped>

</style>