<template>
    <div class="role-information">
        <b-form-group label="Name">
            <b-input type="text" v-model="role.name" />
        </b-form-group>
        <b-form-group label="CPD Hours">
            <b-form-spinbutton v-model="role.cpdHours" min="0" max="1000" step="5"></b-form-spinbutton>
            <small>The number of required CPD hours per year for this role</small>
        </b-form-group>
        <b-form-group label="Clinical Exposure Hours">
            <b-form-spinbutton v-model="role.cceHours" min="0" max="1000" step="5"></b-form-spinbutton>
            <small>The number of required Clinical Exposure hours per year for this role</small>
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_ROLE from '../defaults/defaultRole';

    export default {
        name: "RoleInformation",
        props: {
            role: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_ROLE})
            }
        }
    }
</script>

<style scoped>

</style>