<template>
    <div class="add-training-modal">
        <b-modal ref="modal" title="Add Training" size="lg" header-bg-variant="primary" header-text-variant="light"
                 @ok="create" ok-title="Add Training" no-close-on-backdrop no-close-on-esc :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <TrainingItemInformation :training-item="request" />
            </b-overlay>

        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import TrainingItemInformation from "../partials/TrainingItemInformation";
    import DEFAULT_TRAINING_ITEM from '../defaults/defaultTrainingItem';

    export default {
        name: "AddTrainingModal",
        components: {TrainingItemInformation},
        data() {
            return {
                request: {...DEFAULT_TRAINING_ITEM},
                loading: false,
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.request = {...DEFAULT_TRAINING_ITEM}
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.createTrainingItem(this.request).then(() => {
                    this.$success('Training Item Created', 'The training item was created successfully');
                    this.$emit('training-item-added');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to create training item', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
        created() {
            this.reset();
        }
    }
</script>

<style scoped>

</style>