<template>
    <div class="clinical-skills-group-modal">
        <b-modal :title="modalTitle" size="lg" ref="modal" header-bg-variant="primary" header-text-variant="light" ok-only ok-title="Close" ok-variant="light">
            <b-overlay :show="loading">
                <b-table table-class="light" :fields="fields" :items="skills">
                    <template v-slot:cell(name)="data">
                        {{data.item.name}} <b-badge v-if="data.item.archived">Archived</b-badge>
                    </template>
                    <template v-slot:cell(roles)="data">
                        <b-badge class="mr-2" v-for="role in data.item.roles" v-bind:key="role.id" variant="dark">{{role.name}}</b-badge>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <div class="actions">
                            <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit</b-button>
                            <b-button variant="outline-danger" size="sm" @click="archive(data.item.id)" v-if="!data.item.archived"><ArchiveIcon /> Archive</b-button>
                            <b-button variant="outline-warning" size="sm" @click="unarchive(data.item.id)" v-if="data.item.archived"><RestoreIcon /> Restore</b-button>
                        </div>
                    </template>
                </b-table>
            </b-overlay>
        </b-modal>
        <EditClinicalSkillModal ref="editSkillModal" @skill-updated="getSkills" />
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import EditClinicalSkillModal from "./EditClinicalSkillModal";
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import ArchiveIcon from 'vue-material-design-icons/Archive';
    import RestoreIcon from 'vue-material-design-icons/Restore';

    export default {
        name: "ViewClinicalSkillsGroupModal",
        components: {EditClinicalSkillModal, PencilIcon, ArchiveIcon, RestoreIcon},
        data() {
            return {
                group: null,
                loading: false,
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'expiry',
                        label: 'Expiry (years)'
                    },
                    {
                        key: 'roles',
                        label: 'Roles',
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                skills: []
            }
        },
        computed: {
            modalTitle() {
                return 'Group: ' + ((this.group !== null) ? this.group.name : '');
            }
        },
        methods: {
            show(group) {
                this.skills = [];
                this.group = group;
                this.getSkills();
                this.$refs.modal.show();
            },
            getSkills() {
                this.loading = true;
                Providers.admin.configuration.clinicalSkills(this.group.id).then((resp) => {
                    this.skills = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get skills', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            edit(skillId) {
                this.$refs.editSkillModal.show(this.group.id, skillId);
            },
            refresh() {
                this.getSkills();
            },
            archive(skillId) {
                Providers.admin.configuration.archiveClinicalSkill(this.group.id, skillId).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to archive Clinical Skill', resp);
                })
            },
            unarchive(skillId) {
                Providers.admin.configuration.unarchiveClinicalSkill(this.group.id, skillId).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to restore Clinical Skill', resp);
                })
            }
        }
    }
</script>

<style scoped>

    div.actions {
        min-width: 100px;
    }

    div.actions > * {
        margin-right: 5px;
    }
</style>