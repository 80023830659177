<template>
    <div class="edit-facility-modal">
        <b-modal ref="modal" title="Edit Facility" header-bg-variant="primary" header-text-variant="light" ok-title="Save Changes"
                 ok-variant="primary" :ok-disabled="loading" :cancel-disabled="loading" @ok="save">
            <b-overlay :show="loading">
                <FacilityInformation :facility="facility" v-if="facility" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import FacilityInformation from "../partials/FacilityInformation";
    import Providers from '../../../../providers/index';

    export default {
        name: "EditFacilityModal",
        components: {FacilityInformation},
        data() {
            return {
                unitId: null,
                facilityId: null,
                facility: null,
                loading: false
            }
        },
        methods: {
            show(unitId, facilityId) {
                this.reset();
                this.unitId = unitId;
                this.facilityId = facilityId;
                this.loading = true;
                this.$refs.modal.show();
                Providers.admin.configuration.facility(this.unitId, this.facilityId).then((resp) => {
                    this.facility = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load facility', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateFacility(this.unitId, this.facilityId, this.facility).then(() => {
                    this.$success('Facility Updated', 'The facility has been updated successfully');
                    this.$emit('facility-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update facility', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.unitId = null;
                this.facilityId = null;
                this.facility = null;
            }
        }
    }
</script>

<style scoped>

</style>