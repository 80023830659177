<template>
    <div class="facility-information">
        <b-form-group label="Name">
            <b-form-input type="text" v-model="facility.name" />
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_FACILITY from '../defaults/defaultFacility';

    export default {
        name: "FacilityInformation",
        props: {
            facility: {
                type: Object,
                required: false,
                default: () => ({DEFAULT_FACILITY})
            }
        }
    }
</script>

<style scoped>

</style>