<template>
    <div class="sop-category-information">
        <b-form-group label="Name">
            <b-form-input v-model="category.name" autocomplete="off" />
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_CATEGORY from '../defaults/defaultScopeOfPracticeCategory';

    export default {
        name: "ScopeOfPracticeCategoryInformation",
        props: {
            category: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_CATEGORY})
            }
        },
    }
</script>

<style scoped>

</style>