<template>
    <div class="clinical-skills-group-information">
        <b-form-group label="Name">
            <b-input type="text" v-model="group.name" />
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_GROUP from '../defaults/defaultClinicalSkillsGroup';

    export default {
        name: "ClinicalSkillsGroupInformation",
        props: {
            group: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_GROUP})
            }
        }
    }
</script>

<style scoped>

</style>