<template>
    <div class="add-group-modal">
        <b-modal title="Add Group" ref="modal" @ok="create" ok-title="Create" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <ScopeOfPracticeGroupInformation :group="group" />
        </b-modal>
    </div>
</template>

<script>
    import DEFAULT_GROUP from '../defaults/defaultScopeOfPracticeGroup';
    import Providers from '../../../../providers';
    import ScopeOfPracticeGroupInformation from "../partials/ScopeOfPracticeGroupInformation";

    export default {
        name: "AddScopeOfPracticeGroupModal",
        components: {ScopeOfPracticeGroupInformation},
        data() {
            return {
                loading: false,
                group: null
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.addScopeOfPracticeGroup(this.group).then(() => {
                    this.$success('Group Added', 'The scope of practice group has been added');
                    this.$emit('group-added');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to create group', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.group = {...DEFAULT_GROUP}
            }
        }
    }
</script>

<style scoped>

</style>