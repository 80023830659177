<template>
    <div class="edit-sop-modal">
        <b-modal title="Edit Scope of Practice" ref="modal" @ok="save" ok-title="Save Changes" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <ScopeOfPracticeInformation :scope-of-practice="scopeOfPractice" v-if="scopeOfPractice" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import ScopeOfPracticeInformation from "../partials/ScopeOfPracticeInformation";
    import Providers from '../../../../providers';

    export default {
        name: "EditScopeOfPracticeModal",
        components: {ScopeOfPracticeInformation},
        data() {
            return {
                scopeOfPractice: null,
                groupId: null,
                categoryId: null,
                scopeOfPracticeId: null,
                loading: false,
            }
        },
        methods: {
            show(groupId, categoryId, scopeOfPracticeId) {
                this.$refs.modal.show();
                this.groupId = groupId;
                this.categoryId = categoryId;
                this.scopeOfPracticeId = scopeOfPracticeId;
                this.loading = true;
                Providers.admin.configuration.scopeOfPractice(groupId, categoryId, scopeOfPracticeId).then((resp) => {
                    this.scopeOfPractice = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get Scope of Practice', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateScopeOfPractice(this.groupId, this.categoryId, this.scopeOfPracticeId, this.scopeOfPractice).then(() => {
                    this.$success('Scope of Practice Updated', 'The scope of practice has been updated');
                    this.$emit('scope-of-practice-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update Scope of Practice', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            }

        },
        created() {

        }
    }
</script>

<style scoped>

</style>