import { render, staticRenderFns } from "./ViewScopeOfPracticeGroupModal.vue?vue&type=template&id=2f4f8cd3&scoped=true&"
import script from "./ViewScopeOfPracticeGroupModal.vue?vue&type=script&lang=js&"
export * from "./ViewScopeOfPracticeGroupModal.vue?vue&type=script&lang=js&"
import style0 from "./ViewScopeOfPracticeGroupModal.vue?vue&type=style&index=0&id=2f4f8cd3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4f8cd3",
  null
  
)

export default component.exports