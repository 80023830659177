<template>
    <div class="add-role-modal">
        <b-modal title="Add Group" ref="modal" @ok="create" ok-title="Create" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <ClinicalSkillsGroupInformation :group="group" />
        </b-modal>
    </div>
</template>

<script>

    import Providers from '../../../../providers/index';
    import ClinicalSkillsGroupInformation from "../partials/ClinicalSkillsGroupInformation";
    import DEFAULT_GROUP from '../defaults/defaultClinicalSkillsGroup';

    export default {
        name: "AddClinicalSkillsGroupModal",
        components: {ClinicalSkillsGroupInformation},
        data() {
            return {
                group: {...DEFAULT_GROUP},
                loading: false
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            reset() {
                this.group = {...DEFAULT_GROUP};
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.createClinicalSkillsGroup(this.group.name).then(() => {
                    this.$success('Group Created', 'The group has been created successfully');
                    this.$emit('group-created');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to create group', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
    }
</script>

<style scoped>

</style>