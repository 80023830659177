<template>
    <div class="add-sop-category-modal">
        <b-modal title="Add Category" ref="modal" @ok="create" ok-title="Create" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <ScopeOfPracticeCategoryInformation :category="category" />
        </b-modal>
    </div>
</template>

<script>
    import ScopeOfPracticeCategoryInformation from "../partials/ScopeOfPracticeCategoryInformation";
    import DEFAULT_CATEGORY from '../defaults/defaultScopeOfPracticeCategory';
    import Providers from '../../../../providers';

    export default {
        name: "AddScopeOfPracticeCategoryModal",
        components: {ScopeOfPracticeCategoryInformation},
        data() {
            return {
                category: null,
                groupId: null,
                loading: false,
            }
        },
        methods: {
            show(groupId) {
                this.reset();
                this.groupId = groupId;
                this.$refs.modal.show();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.createScopeOfPracticeCategory(this.groupId, this.category).then(() => {
                    this.$success('Category Added', 'The scope of practice category has been added');
                    this.$emit('category-added');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to add category', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.category = {...DEFAULT_CATEGORY};
            }
        }
    }
</script>

<style scoped>

</style>