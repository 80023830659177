<template>
    <div class="file-upload">
        <b-progress :max="100" variant="primary" striped :value="progressValue" v-if="isUploading" show-progress title="Uploading..." />
        <b-row class="mt-2">
            <b-col sm="9">
                <b-form-file ref="fileInput" v-model="selectedFile" accept="image/png,image/jpg">

                </b-form-file>
            </b-col>
            <b-col sm="3">
                <b-button variant="outline-dark"  :disabled="selectedFile == null" @click="upload"><UploadIcon /> Upload</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import UploadIcon from 'vue-material-design-icons/Upload';

    export default {
        name: "FileUpload",
        props: {
            maxSize: { // in MB
                type: Number,
                required: false,
                default: 2000
            },
            endpoint: {
                type: String,
                required: false,
                default: 'file/upload'
            }
        },
        components: {UploadIcon},
        data() {
            return {
                selectedFile: null,
                progressValue: 0,
                isUploading: false
            }
        },
        watch: {
            selectedFile() {
                if (this.selectedFile == null) {
                    this.$emit('upload-aborted');
                } else {
                    this.$emit('file-selected');
                }
            }
        },
        methods: {
            upload() {
                if (this.selectedFile.size > this.maxSize * 1024 * 1024) {
                    this.$error('File Size', `The file size cannot exceed ${this.maxSize}MB`);
                    return;
                }

                let fileName = this.selectedFile.name;
                this.isUploading = true;
                this.progressValue = 0;

                let reader = new FileReader();
                let vueThis = this;

                reader.onload = (e) => {
                    let contentType = vueThis.selectedFile.type;
                    let data = e.target.result;
                    this.$http.post(`${this.endpoint}?filename=${encodeURIComponent(fileName)}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                vueThis.uploadPercentage = e.loaded / e.total * 100;
                            }
                        },
                        headers: {
                            'Content-Type': contentType
                        }
                    }).then((resp) => {
                        this.$emit('file-uploaded', {storageId: resp.body.storageId});
                        this.reset();
                    }).finally(() => {
                        this.isUploading = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to upload file', resp);
                        this.isUploading = false;
                    });
                };

                reader.readAsArrayBuffer(this.selectedFile);

            },
            reset() {
                this.$refs.fileInput.reset();
            },
            isPending() {
                return this.isUploading || this.selectedFile != null;
            }
        }
    }
</script>

<style scoped>

</style>