<template>
    <div class="sop-category-modal">
        <b-modal :title="modalTitle" size="lg" ref="modal" header-bg-variant="primary" header-text-variant="light" ok-only ok-title="Close" ok-variant="light">
            <b-overlay :show="loading">
                <h2>Scopes of Practice</h2>
                <b-button variant="dark" @click="addScopeOfPractice"><PlusIcon /> Add Scope of Practice</b-button>
                <b-table table-class="light" class="mt-2" :fields="fields" :items="scopeOfPractices">
                    <template v-slot:cell(title)="data">
                        {{data.item.title}} <b-badge v-if="data.item.archived">Archived</b-badge>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <div class="actions">
                            <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit</b-button>
                            <b-button variant="outline-danger" size="sm" @click="archive(data.item.id)" v-if="!data.item.archived"><ArchiveIcon /> Archive</b-button>
                            <b-button variant="outline-warning" size="sm" @click="unarchive(data.item.id)" v-if="data.item.archived"><RestoreIcon /> Restore</b-button>
                        </div>
                    </template>
                </b-table>
            </b-overlay>
        </b-modal>
        <AddScopeOfPracticeModal ref="addScopeOfPracticeModal" @scope-of-practice-added="loadScopesOfPractice" />
        <EditScopeOfPracticeModal ref="editScopeOfPracticeModal" @scope-of-practice-updated="loadScopesOfPractice" />
    </div>
</template>

<script>
    import Providers from '../../../../providers';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import ArchiveIcon from 'vue-material-design-icons/Archive';
    import RestoreIcon from 'vue-material-design-icons/Restore';
    import AddScopeOfPracticeModal from "./AddScopeOfPracticeModal";
    import EditScopeOfPracticeModal from "./EditScopeOfPracticeModal";

    export default {
        name: "ViewScopeOfPracticeCategoryModal",
        components: {EditScopeOfPracticeModal, AddScopeOfPracticeModal, PencilIcon, PlusIcon, ArchiveIcon, RestoreIcon},
        data() {
            return {
                loading: false,
                groupId: null,
                category: null,
                scopeOfPractices: [],
                fields: [
                    {
                        key: 'title',
                        label: 'Title'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        computed: {
            modalTitle() {
                return 'Category: ' + ((this.category !== null) ? this.category.name : '');
            }
        },
        methods: {
            show(groupId, category) {
                this.groupId = groupId;
                this.category = category;
                this.loadScopesOfPractice();
                this.$refs.modal.show();
            },
            loadScopesOfPractice() {
                this.loading = true;
                Providers.admin.configuration.scopesOfPractice(this.groupId, this.category.id).then((resp) => {
                    this.scopeOfPractices = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get scopes of practice', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            edit(scopeOfPracticeId) {
                this.$refs.editScopeOfPracticeModal.show(this.groupId, this.category.id, scopeOfPracticeId);
            },
            addScopeOfPractice() {
                this.$refs.addScopeOfPracticeModal.show(this.groupId, this.category.id);
            },
            reset() {
                this.scopeOfPractices = [];
            },
            refresh() {
                this.loadScopesOfPractice();
            },
            archive(scopeOfPracticeId) {
                Providers.admin.configuration.archiveScopeOfPractice(this.groupId, this.category.id, scopeOfPracticeId).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to update status of Scope of Practice', resp);
                });
            },
            unarchive(scopeOfPracticeId) {
                Providers.admin.configuration.unarchiveScopeOfPractice(this.groupId, this.category.id, scopeOfPracticeId).then(() => {
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to update status of Scope of Practice', resp);
                });
            }
        }
    }
</script>

<style scoped>
    .actions {
        min-width: 150px;
    }

    .actions > * {
        margin-right: 5px;
    }
</style>