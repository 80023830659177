<template>
    <div class="edit-group-modal">
        <b-modal title="Edit Group" ref="modal" @ok="save" ok-title="Save Changes" header-bg-variant="primary" header-text-variant="light"
                 ok-variant="primary" cancel-title="Cancel" :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <ScopeOfPracticeGroupInformation :group="group" v-if="group" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import ScopeOfPracticeGroupInformation from "../partials/ScopeOfPracticeGroupInformation";
    import Providers from '../../../../providers';

    export default {
        name: "EditScopeOfPracticeGroupModal",
        components: {ScopeOfPracticeGroupInformation},
        data() {
            return {
                loading: false,
                groupId: null,
                group: null,
            }
        },
        methods: {
            show(groupId) {
                this.reset();
                this.$refs.modal.show();
                this.groupId = groupId;
                this.loading = true;
                Providers.admin.configuration.scopeOfPracticeGroup(groupId).then((resp) => {
                    this.group = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get group', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            save($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateScopeOfPracticeGroup(this.groupId, this.group).then(() => {
                    this.$success('Group Updated', 'The scope of practice group has been updated');
                    this.$emit('group-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update group', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.groupId = null;
            }
        },
    }
</script>

<style scoped>

</style>