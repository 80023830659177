<template>
    <div class="sop-group">
        <b-form-group label="Name">
            <b-form-input v-model="group.name" autocomplete="off" />
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_GROUP from '../defaults/defaultScopeOfPracticeGroup';

    export default {
        name: "ScopeOfPracticeGroupInformation",
        props: {
            group: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_GROUP})
            }
        },
    }
</script>

<style scoped>

</style>