<template>
    <div class="unit-information">
        <b-form-group label="Name">
            <b-form-input type="text" autocomplete="off" v-model="unit.name"></b-form-input>
        </b-form-group>
        <b-form-group label="Logo">
            <img alt="Logo" :src="logoUrl" v-if="unit.logo" />
            <FileUpload ref="fileUpload" @file-uploaded="handleFileUpload" @upload-aborted="completed(true)" @file-selected="completed(false)" endpoint="admin/organisation-management/logo" :max-size="10"/>
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_UNIT from '../defaults/defaultUnit';
    import FileUpload from '../../../common/FileUpload';

    export default {
        name: "UnitInformation",
        components: {FileUpload},
        props: {
            unit: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_UNIT})
            }
        },
        computed: {
            logoUrl() {
                return `${process.env.VUE_APP_API_ROOT}file/system/${this.unit.logo}`;
            },
        },
        methods: {
            handleFileUpload($e) {
                this.unit.logo = $e.storageId;
                this.completed(true);
            },
            completed(isComplete) {
                if (isComplete) {
                    this.$emit('upload-completed');
                } else {
                    this.$emit('upload-inprogress');
                }
            }
        }
    }
</script>

<style scoped>

</style>