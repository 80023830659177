<template>
    <div class="add-unit-modal">
        <b-modal ref="modal" title="Add Unit" header-bg-variant="primary" header-text-variant="light" ok-title="Add Unit"
                 no-close-on-backdrop no-close-on-esc :cancel-disabled="loading" :ok-disabled="okDisabled" @ok="create">
            <UnitInformation :unit="request" @upload-completed="pendingFileUpload = false" @upload-inprogress="pendingFileUpload = true" />
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../../providers/index';
    import UnitInformation from "../partials/UnitInformation";
    import DEFAULT_UNIT from '../defaults/defaultUnit';

    export default {
        name: "AddUnitModal",
        components: {UnitInformation},
        data() {
            return {
                request: {...DEFAULT_UNIT},
                loading: false,
                pendingFileUpload: false
            }
        },
        computed: {
            okDisabled() {
                return this.loading || this.pendingFileUpload;
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            create(e) {
                e.preventDefault();
                Providers.admin.configuration.createUnit(this.request.name, this.request.logo).then(() => {
                    this.$success('Unit Created', 'The unit was created successfully');
                    this.$emit('unit-added');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to Create Unit', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.request = {...DEFAULT_UNIT};
            },

        },
        mounted() {
            this.reset();
        }
    }
</script>

<style scoped>

</style>