<template>
    <div class="edit-role-modal">
        <b-modal ref="modal" title="Edit Role" header-bg-variant="primary" header-text-variant="light" ok-title="Save Changes" ok-variant="primary" @ok="update" :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <RoleInformation v-if="role" :role="role" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import RoleInformation from "../partials/RoleInformation";
    import Providers from '../../../../providers/index';

    export default {
        name: "EditRoleModal",
        components: {RoleInformation},
        data() {
            return {
                role: null,
                roleId: null,
                loading: false
            }
        },
        methods: {
            show(roleId) {
                this.loading = true;
                this.reset();
                this.$refs.modal.show();
                Providers.admin.configuration.role(roleId).then((resp) => {
                    this.role = resp.body;
                    this.roleId = roleId;
                }).catch((resp) => {
                    this.$httpError('Failed to load role', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            update(e) {
                e.preventDefault();
                this.loading = true;
                Providers.admin.configuration.updateRole(this.roleId, this.role).then(() => {
                    this.$success('Role Updated', 'The role has been updated successfully')
                    this.$emit('role-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update role', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            reset() {
                this.role = null
                this.roleId = null;
            },
            close() {
                this.$refs.modal.hide();
            }
        }
    }
</script>

<style scoped>

</style>