<template>
    <div class="sop-configuration">
        <b-overlay :show="loading">
            <h2>Groups</h2>
            <b-button variant="secondary" @click="addGroup"><PlusIcon /> Create Group</b-button>
            <b-table table-class="light" class="mt-2" :fields="fields" :items="groups">
                <template v-slot:cell(actions)="data">
                    <div class="actions">
                        <b-button size="sm" variant="light" @click="show(data.item)"><FormatListBulletedIcon /> View Categories</b-button>
                        <b-button size="sm" variant="outline-dark" @click="edit(data.item.id)"><PencilIcon /> Edit Group</b-button>
                    </div>
                </template>
            </b-table>
        </b-overlay>
        <AddScopeOfPracticeGroupModal ref="addGroupModal" @group-added="loadGroups" />
        <EditScopeOfPracticeGroupModal ref="editGroupModal" @group-updated="loadGroups" />
        <ViewScopeOfPracticeGroupModal ref="viewGroupModal" />
    </div>
</template>

<script>
    import PlusIcon from 'vue-material-design-icons/Plus';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted';
    import Providers from '../../../providers';
    import AddScopeOfPracticeGroupModal from "./modal/AddScopeOfPracticeGroupModal";
    import EditScopeOfPracticeGroupModal from "./modal/EditScopeOfPracticeGroupModal";
    import ViewScopeOfPracticeGroupModal from "./modal/ViewScopeOfPracticeGroupModal";

    export default {
        name: "ScopeOfPracticeConfig",
        components: {
            ViewScopeOfPracticeGroupModal,
            EditScopeOfPracticeGroupModal, AddScopeOfPracticeGroupModal, PlusIcon, PencilIcon, FormatListBulletedIcon},
        data() {
            return {
                groups: [],
                loading: false,
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        methods: {
            loadGroups() {
                this.loading = true;
                Providers.admin.configuration.scopesOfPracticeGroups().then((resp) => {
                    this.groups = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load groups', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            addGroup() {
                this.$refs.addGroupModal.show();
            },
            edit(groupId) {
                this.$refs.editGroupModal.show(groupId);
            },
            show(group) {
                this.$refs.viewGroupModal.show(group);
            }
        },
        created() {
            this.loadGroups();
        }
    }
</script>

<style scoped>
.actions > * {
    margin-right: 5px;
}
</style>